import React, { useEffect, useState } from "react";
import { IonInput } from "@ionic/react";
import { Controller, SubmitHandler, useController, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import NxuSelect from "components/atom/nxu-select";
import NxuInstitutionField from "components/atom/nxu-institution-field";
import { InstitutionSelectItem } from "components/atom/nxu-institution-field/nxu-institution-field";
import EducationTranscriptUpload from "components/molecule/education-transcript-upload";
import { FetchEducationProfileResponse } from "types/learner-profile";
import { SelectItem } from "types/forms";

// Constants for the Education Level values
const BACHELORS_DEGREE = "Bachelor's Degree";
const ASSOCIATE_DEGREE = "Associate Degree";
const MASTERS_DEGREE = "Master's Degree or Higher";
const OTHER_DEGREE = "Other";

const EDUCATION_LEVEL_OPTIONS: SelectItem[] = [
  { label: "Associate Degree", value: ASSOCIATE_DEGREE },
  { label: "Bachelor's Degree", value: BACHELORS_DEGREE },
  { label: "Master's Degree or Higher", value: MASTERS_DEGREE },
  { label: "Other", value: OTHER_DEGREE },
];

const BUSINESS_RELATED_OPTIONS: SelectItem[] = [
  { label: "Yes, my degree is in a business-related field", value: "Yes" },
  { label: "No, my degree is not in a business-related field", value: "No" },
  { label: "I'm unsure if my degree was in a business-related field", value: "Unsure" },
];

const educationFormSchema = yup.object().shape({
  ProductCode: yup.string(),
  EducationLevel: yup.string().required("Your education level is a required field").max(40),
  EducationLevelOther: yup.string().when("EducationLevel", {
    is: (val: any) => val === OTHER_DEGREE,
    then: (schema) =>
      schema
        .required("Your education level is required")
        .max(250, "Your education level should not be more than 250 characters"),
  }),
  DegreeName: yup
    .string()
    .trim()
    .when("EducationLevel", {
      is: (val: any) => val === BACHELORS_DEGREE || val === MASTERS_DEGREE,
      then: (schema) =>
        schema.required("Your degree is required").max(250, "Your degree should not be more than 250 characters"),
    }),
  GraduationYear: yup
    .string()
    .trim()
    .when("EducationLevel", {
      is: (val: any) => val === BACHELORS_DEGREE || val === MASTERS_DEGREE,
      then: (schema) => schema.required("Your graduation year is required"),
    }),
  InstitutionName: yup.string().trim(),
  UnaccreditedInstitutionName: yup.string().trim(),
  InstitutionSelect: yup.string().when(["InstitutionName", "UnaccreditedInstitutionName"], {
    is: (val1: any, val2: any) => !val1 && !val2,
    then: (schema) => schema.required("Your institution name is a required field"),
  }),
  BusinessRelated: yup.string().when(["EducationLevel", "ProgramCode"], {
    is: (level: any, code: string) =>
      code !== "MSDT" && code !== "MSBA" && (level === "Bachelor's Degree" || level === "Master's Degree or Higher"),
    then: (schema) => schema.required("Please state whether your degree is business-related or not"),
  }),
  UnofficialTranscriptFileId: yup.string(),
  UnofficialTranscriptFileName: yup.string(),
});

export interface EducationFormProps {
  submitEventInProgress: boolean;
  submitEventLabel: string;
  submitEventInProgressLabel: string;
  parentFormHandler: SubmitHandler<any>;
  existingApplicantInfo?: FetchEducationProfileResponse;
  productCode?: string;
}

/**
 * MBA applicants must go through a screening form first
 */
const EducationForm = (props: EducationFormProps) => {
  const {
    submitEventInProgress,
    submitEventLabel,
    submitEventInProgressLabel,
    parentFormHandler,
    existingApplicantInfo,
    productCode,
  } = props;

  const [isBusinessRelatedVisible] = useState(productCode !== "MSBA" && productCode !== "MSDT");
  const [areDegreeQuestionsVisible, setAreDegreeQuestionsVisible] = useState(false);
  const [isEducationLevelOtherVisible, setIsEducationLevelOtherVisible] = useState(false);
  const [isTranscriptVisible] = useState(
    productCode === "MBA" || productCode === "MSDT" || productCode === "MSBA" || productCode === "MSE",
  );
  const [transcriptFile, setTranscriptFile] = useState<
    { originalFileName: string; uploadedFileName: string } | undefined
  >(
    existingApplicantInfo?.UnofficialTranscriptFileId
      ? {
          uploadedFileName: existingApplicantInfo.UnofficialTranscriptFileId || "",
          originalFileName: existingApplicantInfo.UnofficialTranscriptFileName || "",
        }
      : undefined,
  );

  const currentYear = new Date().getFullYear();
  const yearsList: Array<SelectItem> = Array.from({ length: 100 }, (_, index) => ({
    label: currentYear - index,
    value: currentYear - index,
  }));

  const existingEduLvl = () => {
    if (existingApplicantInfo?.HighestEducationLevel) {
      if (
        existingApplicantInfo.HighestEducationLevel !== BACHELORS_DEGREE &&
        existingApplicantInfo.HighestEducationLevel !== MASTERS_DEGREE &&
        existingApplicantInfo.HighestEducationLevel !== ASSOCIATE_DEGREE
      ) {
        return OTHER_DEGREE;
      }
      return existingApplicantInfo.HighestEducationLevel;
    }
    return "";
  };

  const businessRelatedVal = () =>
    (!isBusinessRelatedVisible && "NA") ||
    (existingApplicantInfo?.IsBusinessRelated && "Yes") ||
    (existingApplicantInfo?.IsBusinessRelated === false && "No") ||
    "";

  const educationLevelOtherVal = () =>
    (existingEduLvl() === OTHER_DEGREE && existingApplicantInfo?.HighestEducationLevel) || "";

  const degreeNameVal = () =>
    (existingEduLvl() !== OTHER_DEGREE && existingApplicantInfo?.DegreeOrCertificateName) || "";

  const graduationYearVal = () =>
    (existingApplicantInfo?.GraduationYear && existingApplicantInfo?.GraduationYear.toString()) || "";

  const newEducationForm = useForm({
    defaultValues: {
      ProductCode: productCode,
      EducationLevel: existingEduLvl(),
      EducationLevelOther: educationLevelOtherVal(),
      DegreeName: degreeNameVal(),
      GraduationYear: graduationYearVal(),
      InstitutionName: (existingApplicantInfo?.Accredited && existingApplicantInfo?.InstitutionName) || "",
      UnaccreditedInstitutionName: (!existingApplicantInfo?.Accredited && existingApplicantInfo?.InstitutionName) || "",
      BusinessRelated: businessRelatedVal(),
      UnofficialTranscriptFileId: existingApplicantInfo?.UnofficialTranscriptFileId || "",
      UnofficialTranscriptFileName: existingApplicantInfo?.UnofficialTranscriptFileName || "",
    },
    resolver: yupResolver(educationFormSchema),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = newEducationForm;

  const {
    field: { value: EducationLevelValue, onChange: EducationLevelOnChange },
  } = useController({ name: "EducationLevel", control });
  const {
    field: { value: BusinessRelatedValue, onChange: BusinessRelatedOnChange },
  } = useController({ name: "BusinessRelated", control });
  const {
    field: { value: graduationYearValue, onChange: graduationYearOnChange },
  } = useController({ name: "GraduationYear", control });
  const {
    field: { value: institutionNameValue },
  } = useController({ name: "InstitutionName", control });
  const {
    field: { value: unaccreditedInstitutionNameValue },
  } = useController({ name: "UnaccreditedInstitutionName", control });

  const handleEducationFormSubmit: SubmitHandler<any> = async (formValues) => {
    if (Object.keys(errors).length !== 0) return;
    parentFormHandler(formValues);
  };

  const institutionToPayload = (selectedInstitution?: InstitutionSelectItem) => {
    clearErrors("InstitutionSelect");
    if (selectedInstitution) {
      // eslint-disable-next-line no-underscore-dangle
      if (selectedInstitution.__isNew__) {
        setValue("InstitutionName", "", { shouldValidate: true, shouldTouch: true });
        setValue("UnaccreditedInstitutionName", selectedInstitution.value, { shouldValidate: true, shouldTouch: true });
      } else {
        // We want to store the Institution Name from the label, as we don't make use of the ID value in our other systems
        setValue("InstitutionName", selectedInstitution.label, { shouldValidate: true, shouldTouch: true });
        setValue("UnaccreditedInstitutionName", "", { shouldValidate: true, shouldTouch: true });
      }
    } else {
      setValue("InstitutionName", "", { shouldValidate: true, shouldTouch: true });
      setValue("UnaccreditedInstitutionName", "", { shouldValidate: true, shouldTouch: true });
    }
  };

  useEffect(() => {
    if (existingApplicantInfo) {
      setAreDegreeQuestionsVisible(
        existingApplicantInfo.HighestEducationLevel === BACHELORS_DEGREE ||
          existingApplicantInfo.HighestEducationLevel === MASTERS_DEGREE,
      );
      setIsEducationLevelOtherVisible(
        !!existingApplicantInfo.HighestEducationLevel &&
          existingApplicantInfo.HighestEducationLevel !== BACHELORS_DEGREE &&
          existingApplicantInfo.HighestEducationLevel !== MASTERS_DEGREE &&
          existingApplicantInfo.HighestEducationLevel !== ASSOCIATE_DEGREE,
      );
    }
  }, [existingApplicantInfo]);

  useEffect(() => {
    if (!areDegreeQuestionsVisible) {
      setValue("BusinessRelated", "");
      setValue("DegreeName", "");
      setValue("GraduationYear", "");
    } else {
      setValue("BusinessRelated", businessRelatedVal());
      setValue("DegreeName", degreeNameVal());
      setValue("GraduationYear", graduationYearVal());
    }
  }, [areDegreeQuestionsVisible]);

  useEffect(() => {
    if (!isEducationLevelOtherVisible) {
      setValue("EducationLevelOther", "");
    } else {
      setValue("EducationLevelOther", educationLevelOtherVal());
    }
  }, [isEducationLevelOtherVisible]);

  useEffect(() => {
    setValue("UnofficialTranscriptFileId", transcriptFile?.uploadedFileName);
    setValue("UnofficialTranscriptFileName", transcriptFile?.originalFileName);
  }, [transcriptFile]);

  return (
    <form className="education-form" data-testid="education-form" onSubmit={handleSubmit(handleEducationFormSubmit)}>
      <Controller
        control={control}
        name="EducationLevel"
        render={({ field, fieldState }) => (
          <NxuSelect
            selectedValue={EducationLevelValue}
            onChange={(option) => {
              setAreDegreeQuestionsVisible(option.value === BACHELORS_DEGREE || option.value === MASTERS_DEGREE);
              setIsEducationLevelOtherVisible(option.value === OTHER_DEGREE);
              if (option.value !== OTHER_DEGREE) setValue("EducationLevelOther", "");
              EducationLevelOnChange(option.value);
            }}
            data-hj-allow
            options={EDUCATION_LEVEL_OPTIONS}
            field={field}
            fieldState={fieldState}
            placeholder={"What is your highest level of education?"}
            ariaLabel={"What is your highest level of education?"}
            isDisabled={submitEventInProgress}
          />
        )}
      />

      {isEducationLevelOtherVisible && (
        <Controller
          control={control}
          name="EducationLevelOther"
          render={({ field, fieldState }) => (
            <IonInput
              fill="outline"
              disabled={submitEventInProgress}
              onInput={field.onChange}
              onIonBlur={field.onBlur}
              type="text"
              placeholder="Fill in your education level"
              aria-label="Fill in your education level"
              errorText={fieldState.error?.message}
              className={fieldState.error ? "ion-touched ion-invalid" : ""}
              value={field.value}
            />
          )}
        />
      )}

      {areDegreeQuestionsVisible && (
        <>
          {isBusinessRelatedVisible && (
            <Controller
              control={control}
              name="BusinessRelated"
              render={({ field, fieldState }) => (
                <NxuSelect
                  selectedValue={BusinessRelatedValue}
                  onChange={(option) => BusinessRelatedOnChange(option.value)}
                  options={BUSINESS_RELATED_OPTIONS}
                  field={field}
                  fieldState={fieldState}
                  placeholder={"Was your degree business related?"}
                  ariaLabel={"Was your degree business related?"}
                  isDisabled={submitEventInProgress}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="DegreeName"
            render={({ field, fieldState }) => (
              <IonInput
                fill="outline"
                disabled={submitEventInProgress}
                onInput={field.onChange}
                onIonBlur={field.onBlur}
                type="text"
                placeholder="Enter the name of your degree"
                aria-label="Enter the name of your degree"
                errorText={fieldState.error?.message}
                className={fieldState.error ? "ion-touched ion-invalid" : ""}
                value={field.value}
              />
            )}
          />
          <Controller
            control={control}
            name="GraduationYear"
            render={({ field, fieldState }) => (
              <NxuSelect
                selectedValue={graduationYearValue}
                onChange={(option) => graduationYearOnChange(option.value)}
                options={yearsList}
                field={field}
                fieldState={fieldState}
                placeholder={"Choose graduation year"}
                ariaLabel={"Choose graduation year"}
                isDisabled={submitEventInProgress}
              />
            )}
          />
        </>
      )}
      <Controller
        control={control}
        name="InstitutionSelect"
        render={({ fieldState }) => (
          <NxuInstitutionField
            onAutocompleteLoadFailed={() =>
              setError("InstitutionSelect", {
                type: "manual",
                message: "The Institution lookup returned no results",
              })
            }
            fieldName="InstitutionSelect"
            defaultValue={institutionNameValue || unaccreditedInstitutionNameValue || ""}
            onChange={(option) => institutionToPayload(option)}
            placeholder={"Select the university/college you graduated from"}
            fieldState={fieldState}
            isDisabled={submitEventInProgress}
          />
        )}
      />
      {isTranscriptVisible && (
        <EducationTranscriptUpload
          setUploadResponse={setTranscriptFile}
          inProgress={submitEventInProgress}
          existingOriginalFileName={existingApplicantInfo?.UnofficialTranscriptFileName || ""}
        />
      )}
      <NxuPrimaryButton type="submit" expand="block" disabled={submitEventInProgress}>
        {submitEventInProgress ? submitEventInProgressLabel : submitEventLabel}
      </NxuPrimaryButton>
    </form>
  );
};

export default EducationForm;
