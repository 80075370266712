import React from "react";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { NEXFORD_LEARNER_STORIES, NEXFORD_YOUTUBE } from "constants/external-routes";

import CardPanel from "components/atom/card-panel";

import "./pending-page.scss";

/**
 * ApplyNXU Application Pending
 */
const PendingPage = () => (
  <CardPanel centre className="pending-page__info-panel" testId="program-status-pending-panel">
    <h1>Thank you for applying to Nexford!</h1>
    <IonIcon icon={checkmarkCircleOutline} />
    <h2>Application under review</h2>
    <p>We have received your completed application and are reviewing it.</p>
    <p>A member of our admissions team will be in touch with you shortly with regards to the admissions decision.</p>
    <p>
      Why not also visit our{" "}
      <a href={NEXFORD_YOUTUBE} target="_blank" rel="noreferrer">
        YouTube channel
      </a>{" "}
      or listen to some other{" "}
      <a href={NEXFORD_LEARNER_STORIES} target="_blank" rel="noreferrer">
        learners' stories
      </a>{" "}
      about studying at Nexford.
    </p>
  </CardPanel>
);

export default PendingPage;
